import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('../pages/splash-screen/splash-screen.component').then(m => m.SplashScreenComponent),
  },
  {
    path: ':id',
    loadComponent: () => import('../pages/home/home.component').then(m => m.HomeComponent),
  },
  {
    path: 'detail/:id',
    loadComponent: () => import('../pages/page/page.component').then(m => m.PageComponent)
  }
]; 
