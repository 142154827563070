import {
  provideHttpClient,
  withFetch
} from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

import { appRoutes } from './core/app.route';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes),
    provideAnimations(),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    importProvidersFrom(
      ShareButtonsModule,
      ShareIconsModule
    )
  ],
};
